<template>
    <v-card class="ma-4">
        <v-card-title>
            {{ title }}

            <v-divider
                    class="mx-6"
                    inset
                    vertical
            ></v-divider>


            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>
            <v-spacer></v-spacer>

            <v-text-field
                    v-model="search"
                    append-icon="mdi-magnify"
                    clearable
                    filled
                    hide-details
                    label="Search about something"
                    single-line
            ></v-text-field>

        </v-card-title>

        <v-dialog
                v-model="dialogReject"
                max-width="425"
                persistent
        >
            <v-card>
                <v-card-title>
                    Reject Job
                </v-card-title>

                <v-card-text class=" ">
                    Are you sure for continue reject this job ?

                    <v-col cols="12">
                        <v-textarea v-model="note" :counter="1000" :disabled="buttonLoading" :rules="noteRules " clearable filled label="Notes" no-resize
                                    rows="5"
                        ></v-textarea>
                    </v-col>
                </v-card-text>

                <v-card-actions>


                    <v-spacer></v-spacer>

                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="primary"
                            text
                            @click="dialogReject=false"
                    >
                        Back
                    </v-btn>

                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="red darken-2"
                            text
                            @click="clickChangeJobStatus(false)"

                    >
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
                v-model="dialogAccept"
                max-width="425"
                persistent
        >
            <v-card>
                <v-card-title>
                    Accept Job
                </v-card-title>

                <v-card-text class=" ">
                    Are you sure for continue accept this job ?
                </v-card-text>

                <v-card-actions>


                    <v-spacer></v-spacer>

                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="primary"
                            text
                            @click="dialogAccept=false"
                    >
                        Back
                    </v-btn>

                    <v-btn
                            :disabled="buttonLoading"
                            class="body-2"
                            color="red darken-2"
                            text
                            @click="clickChangeJobStatus"

                    >
                        Continue
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialogJob" fullscreen
                  hide-overlay
                  transition="dialog-bottom-transition">

            <v-card v-if="dialogJob">
                <v-toolbar
                        color="blue-grey"
                        dark
                >
                    <v-btn
                            dark
                            icon
                            @click="dialogJob = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Job Info</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-toolbar-items
                            v-if="((tab===1 && (item.preQuestionStatus==null || item.preQuestionStatus==2)) ||(tab===2 &&  (item.postQuestionStatus==null || item.postQuestionStatus==2)) ||(tab===3 &&  (item.imageEvidenceStatus==null || item.imageEvidenceStatus==2))  )">
                        <v-btn
                                :disabled="(!valid || buttonLoading)"
                                :loading="buttonLoading"
                                dark
                                text
                                @click="validate(false)"
                        >Save
                        </v-btn>
                        <v-btn
                                :disabled="(!valid || buttonLoading)"
                                :loading="buttonLoading"
                                class="black--text"
                                color="white"
                                dark
                                @click="validate"
                        >Submit
                        </v-btn>
                    </v-toolbar-items>
                    <template v-slot:extension>
                        <v-tabs
                                v-model="tab"
                                center-active
                                centered
                                show-arrows

                        >
                            <v-tabs-slider color="amber"></v-tabs-slider>

                            <v-tab
                                    v-for="(tabItem,index) in tabItems"
                                    :key="index"
                                    v-model="tab"
                            >
                                {{ tabItem }}
                            </v-tab>
                        </v-tabs>
                    </template>
                </v-toolbar>

                <v-alert
                        v-if="Object.keys(errors).length>0"
                        dense
                        text
                        type="error"
                >
                    <h4 class="subtitle">Correct the following errors:</h4>
                    <ul>
                        <li
                                v-for="(error, index) in errors"
                                :key="index"
                        >{{ error.message || error }}
                        </li>
                    </ul>
                </v-alert>

                <v-card-text>
                    <v-container>

                        <v-tabs-items v-model="tab">
                            <v-tab-item
                                    v-for="tabItem in tabItems"
                                    :key="tabItem"
                                    class="mt-4">

                                <div v-if="tab==0" class="ma-4">
                                    <v-expansion-panels v-model="expPanel" multiple>
                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">
                                                Customer Details
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-divider></v-divider>
                                                <div class="flex d-flex   flex-wrap my-4 ">

                                                    <div>
                                                        <v-chip class="mr-1" label small>Name</v-chip>
                                                        {{ item.User.name }}
                                                    </div>
                                                    <div>
                                                        <v-chip class="mr-1  ml-6" label small>Phone Number</v-chip>
                                                        {{ item.User.username }}
                                                    </div>
                                                </div>
                                                <div class="my-4">
                                                    <v-chip class="mr-1  " label small>Status</v-chip>
                                                    <v-chip :color="statusOrderColor(item.status)" dark label small> {{
                                                            checkOrderStatus(item.status)
                                                        }}
                                                    </v-chip>
                                                </div>
                                                <div class="my-4">
                                                    <v-chip class="mr-1  " label small>Pre-Installation Status</v-chip>
                                                    <v-chip color="red" dark label small>Pre-Installation Questionnaire Submitted</v-chip>
                                                </div>
                                                <div class="my-4">
                                                    <v-chip class="mr-1" label small>Post-Installation Status</v-chip>
                                                    <v-chip color="orange" dark label small>Post-Installation Questionnaire Submitted</v-chip>
                                                </div>

                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">
                                                Job Overview
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-divider></v-divider>
                                                <div class=" flex-wrap my-4 ">


                                                    <div class="my-4">
                                                        <v-chip class="mr-1" label small>Boiler Type
                                                        </v-chip>
                                                        {{ item.Product.BoilerType.name }}
                                                    </div>
                                                    <div class="my-4">
                                                        <v-chip class="mr-1 " label small>Boiler Name</v-chip>
                                                        {{ item.Product.name }}
                                                    </div>
                                                    <div class="my-4">
                                                        <v-chip class="mr-1  " label small>Installation Date</v-chip>
                                                        {{ item.installDate }}
                                                    </div>
                                                </div>
                                                <div class="my-4">
                                                    <v-chip class="mr-1" label small>Installation Location</v-chip>
                                                    {{ item.AddressOrder.installCity }} , {{ item.AddressOrder.installPostcode }} ,
                                                    {{ item.AddressOrder.installLine1 }} ,
                                                    {{ item.AddressOrder.installLine2 }}
                                                </div>


                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">
                                                Installation Details
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-divider></v-divider>
                                                <v-row class="mt-1">
                                                    <v-col v-for="(parent,index) in questions.filter(c => c.parentID === 0 && c.isActive===true)" :key="index" cols="12" lg="4"
                                                           md="6"
                                                           sm="12">


                                                        <div class="mb-2">{{ parent.name }}</div>
                                                        <div class=" orange--text mb-2">
                                                            {{ item.OrderQuestions.filter(c => c.questionID === parent.id)[0].Answer.name }}
                                                        </div>

                                                    </v-col>
                                                </v-row>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">
                                                Order Details
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-divider></v-divider>

                                                <v-list-item>
                                                    <v-list-item-avatar size="80">
                                                        <v-img :src="previewImage(item.Product.image)"></v-img>
                                                    </v-list-item-avatar>
                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <v-chip color="primary" label small>{{ item.Product.name }}</v-chip>
                                                        </v-list-item-title>
                                                    </v-list-item-content>
                                                </v-list-item>

                                                <v-list-item
                                                        v-for="(addonOrder,index) in item.AddonOrders"
                                                        :key="index"
                                                >

                                                    <v-list-item-avatar size="80">
                                                        <v-img :src="previewImage(addonOrder.Addon.image)"></v-img>
                                                    </v-list-item-avatar>


                                                    <v-list-item-content>
                                                        <v-list-item-title>
                                                            <v-chip label small>{{ addonOrder.Addon.name }}</v-chip>
                                                        </v-list-item-title>


                                                    </v-list-item-content>
                                                </v-list-item>


                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                        <v-expansion-panel>
                                            <v-expansion-panel-header class="font-weight-bold">
                                                Exiting boiler photos
                                            </v-expansion-panel-header>
                                            <v-expansion-panel-content>
                                                <v-divider></v-divider>
                                            </v-expansion-panel-content>
                                        </v-expansion-panel>

                                    </v-expansion-panels>
                                </div>

                                <div v-if="tab==1" class="ma-4">
                                    <v-form
                                            :ref="'form_'+tab"
                                            v-model="valid"
                                            :readonly="item.preQuestionStatus===0 || item.preQuestionStatus===1"
                                            lazy-validation
                                    >
                                        <v-row>

                                            <v-col cols="12">
                                                <v-alert
                                                        v-if="(item.preQuestionStatus!=null)"
                                                        :type="(item.preQuestionStatus===0)?'warning':(item.preQuestionStatus===1)?'success':'error'"
                                                        dense
                                                        text
                                                >
                                                    <h4 class="body-1">{{
                                                            (item.preQuestionStatus === 0) ? 'Pre - Install questions has been sent to Admin and waiting for his agreement...' : (item.preQuestionStatus === 1) ? 'Pre - Install questions has been accepted by admin' : 'Pre - Install questions has been rejected by admin ... please re-fill the question and re-submit it.'
                                                        }}</h4>
                                                </v-alert>
                                            </v-col>

                                            <v-col v-for="(installQuestion,index) in installQuestions.filter((e)=>e.isPreInstall)" :key="index" cols="12" lg="4"
                                                   md="6"
                                                   sm="12">

                                                <v-radio-group
                                                        v-if="installQuestion.type===0 ||installQuestion.type===1"
                                                        v-model="preQuestions[installQuestion.id]" :label="installQuestion.name"
                                                        :rules="[v => (Number(v)>-1) || 'You must select one of theme']" inline="true"
                                                >

                                                    <div class="d-inline-flex align-start ml-2 ">
                                                        <v-radio v-if="installQuestion.type===0 ||installQuestion.type===1" :value="0"
                                                                 label="No"></v-radio>
                                                        <v-radio v-if="installQuestion.type===0 ||installQuestion.type===1" :value="1" class="mx-6"
                                                                 label="Yes"></v-radio>
                                                        <v-radio v-if="installQuestion.type===1" :value="2"
                                                                 label="Na"></v-radio>

                                                    </div>
                                                </v-radio-group>

                                                <v-textarea v-else v-model="preQuestions[installQuestion.id]" :label="installQuestion.name"
                                                            :rules="[ v => !!v || 'Field is required',v => (v && v.length <= 150) ||  'Field must be less than 150 characters']"
                                                            auto-grow class="mb-2"></v-textarea>


                                            </v-col>

                                        </v-row>
                                    </v-form>

                                </div>

                                <div v-if="tab==2" class="ma-4">

                                    <v-form
                                            :ref="'form_'+tab"
                                            v-model="valid"
                                            :readonly="item.postQuestionStatus===0 || item.postQuestionStatus===1"
                                            lazy-validation>
                                        <v-row>

                                            <v-col cols="12">
                                                <v-alert
                                                        v-if="(item.postQuestionStatus!=null)"
                                                        :type="(item.postQuestionStatus===0)?'warning':(item.postQuestionStatus===1)?'success':'error'"
                                                        dense
                                                        text
                                                >
                                                    <h4 class="body-1">{{
                                                            (item.postQuestionStatus === 0) ? 'Post - Install questions has been sent to Admin and waiting for his agreement...' : (item.postQuestionStatus === 1) ? 'Post - Install questions has been accepted by admin' : 'Post - Install questions has been rejected by admin ... please re-fill the question and re-submit it.'
                                                        }}</h4>
                                                </v-alert>
                                            </v-col>

                                            <v-col v-for="(installQuestion,index) in installQuestions.filter((e)=>!e.isPreInstall)" :key="index" cols="12" lg="4"
                                                   md="6"
                                                   sm="12">

                                                <v-radio-group
                                                        v-if="installQuestion.type===0 ||installQuestion.type===1"
                                                        v-model="postQuestions[installQuestion.id]" :label="installQuestion.name"
                                                        :rules="[v => (Number(v)>-1) || 'You must select one of theme']" inline="true"
                                                >

                                                    <div class="d-inline-flex align-start ml-2 ">
                                                        <v-radio v-if="installQuestion.type===0 ||installQuestion.type===1" :value="0"
                                                                 label="No"></v-radio>
                                                        <v-radio v-if="installQuestion.type===0 ||installQuestion.type===1" :value="1" class="mx-6"
                                                                 label="Yes"></v-radio>
                                                        <v-radio v-if="installQuestion.type===1" :value="2"
                                                                 label="Na"></v-radio>

                                                    </div>
                                                </v-radio-group>

                                                <v-textarea v-else v-model="postQuestions[installQuestion.id]" :label="installQuestion.name" :rules="[ v => !!v || 'Field is required',v => (v && v.length <= 150) ||  'Field must be less than 150 characters']"
                                                            class="mb-2"
                                                            clearable rows="2"></v-textarea>


                                            </v-col>

                                        </v-row>
                                    </v-form>

                                </div>

                                <div v-if="tab==3" class="ma-4">

                                    <v-form
                                            :ref="'form_'+tab"
                                            v-model="valid"
                                            :readonly="item.imageEvidenceStatus===0 || item.imageEvidenceStatus===1"
                                            lazy-validation
                                    >

                                        <v-row>

                                            <v-col cols="12">
                                                <v-alert
                                                        v-if="(item.imageEvidenceStatus!=null)"
                                                        :type="(item.imageEvidenceStatus===0)?'warning':(item.imageEvidenceStatus===1)?'success':'error'"
                                                        dense
                                                        text
                                                >
                                                    <h4 class="body-1">{{
                                                            (item.imageEvidenceStatus === 0) ? 'Images Evidences has been sent to Admin and waiting for his agreement...' : (item.imageEvidenceStatus === 1) ? 'Images Evidences has been accepted by admin' : 'Images Evidences has been rejected by admin ... please re-upload the images and re-submit it.'
                                                        }}</h4>
                                                </v-alert>
                                            </v-col>

                                            <v-col v-for="(imageType,index) in imageTypes.filter(e=>e.parentID==1)" :key="index" cols="12" lg="4"
                                                   md="6" sm="12">
                                                <v-card
                                                        class="ma-4">
                                                    <v-card-actions>
                                                        <v-list-item>

                                                            <v-list-item-content>
                                                                <v-col cols="12">
                                                                    <v-file-input
                                                                            v-model="imageEvidences[imageType.id]"
                                                                            :disabled="((checkImageOrder(imageType).length<imageType.maxAllowed) &&  (item.imageEvidenceStatus==null || item.imageEvidenceStatus==2))?false:true"
                                                                            :label="imageType.name"
                                                                            :prepend-icon="null"
                                                                            :rules="checkImageOrder(imageType).length>0?[]:imageRules"
                                                                            accept="image/png, image/jpeg, image/bmp"
                                                                            outlined
                                                                    ></v-file-input>
                                                                </v-col>
                                                            </v-list-item-content>

                                                        </v-list-item>


                                                    </v-card-actions>

                                                    <v-expand-transition>
                                                        <div v-show="true">
                                                            <v-divider></v-divider>
                                                            <v-card-text>
                                                                <div v-for="(imageOrder,indexOrder)  in checkImageOrder(imageType)"
                                                                     :key="indexOrder"
                                                                     class="d-inline-flex">
                                                                    <div class=" mt-2">
                                                                        <v-avatar class="mr-2 " size="90">
                                                                            <img :src="previewImage(imageOrder.image)"
                                                                                 @click="previewImage(imageOrder.image,true)">
                                                                        </v-avatar>
                                                                        <div class="d-block  ml-2 mt-2">
                                                                            <v-btn
                                                                                    :onclick="downloadImage(imageOrder.image)"
                                                                                    class=""
                                                                                    color="primary"
                                                                                    icon
                                                                            >
                                                                                <v-icon>mdi-progress-download</v-icon>
                                                                            </v-btn>
                                                                            <v-btn
                                                                                    :onclick="downloadImage(imageOrder.image)"
                                                                                    class=" "
                                                                                    color="red"
                                                                                    icon
                                                                            >
                                                                                <v-icon>mdi-delete</v-icon>
                                                                            </v-btn>
                                                                        </div>

                                                                    </div>

                                                                </div>
                                                            </v-card-text>
                                                        </div>
                                                    </v-expand-transition>
                                                </v-card>
                                            </v-col>

                                        </v-row>
                                    </v-form>

                                </div>

                            </v-tab-item>
                        </v-tabs-items>

                    </v-container>
                </v-card-text>

                <v-dialog v-if="showDialogImage" v-model="showDialogImage" max-width="30%" @keydown.esc="showDialogImage=false">
                    <v-card>
                        <v-img :src="imageUrl" alt="" class="fill-height" contain/>
                    </v-card>
                </v-dialog>
            </v-card>


        </v-dialog>


        <v-data-table
                :footer-props="{'items-per-page-options': [10, 20, 40, 80, 100]}"
                :headers="headers"
                :items="desserts"
                :items-per-page="10"
                :loading="loading"
                :options.sync="options"
                :search="search"
                class="elevation-1"
        >


            <template v-slot:item.actions="{ item }">

                <v-btn
                        v-if="item.status==0"
                        class="mr-3"
                        color="red"
                        dark
                        small
                        @click="showDialogReject(item.id)"
                >Reject
                </v-btn>
                <v-btn
                        v-if="item.status==0"
                        color="green"
                        dark
                        small
                        @click="showDialogAccept(item.id)"
                >Accept
                </v-btn>

                <v-btn
                        v-if="item.status==1"
                        dark
                        small
                        @click="showDialogJob(item.orderID)"
                >View
                </v-btn>


            </template>

            <template v-slot:item.Order.Product.BoilerType.name="{ item }">

                <v-chip color="indigo" dark label>{{ item.Order.Product.BoilerType.name }}</v-chip>


            </template>

            <template v-slot:item.Order.Product.name="{ item }">

                <v-chip color="primary" dark>{{ item.Order.Product.name }}</v-chip>

            </template>

            <template v-slot:item.status="{ item }">
                <v-chip :color="statusColor(item.status)" dark label>{{ checkStatus(item.status) }}</v-chip>
            </template>

            <template v-slot:item.isActive="{ item }">
                <v-icon :color="(item.isActive)?'green':'red'">
                    mdi-eye
                </v-icon>
            </template>


        </v-data-table>
    </v-card>
</template>

<script>
import networks from '../../services/networks';
import {
    pathImageUrl,
    urlAssignEngineers,
    urlCreateImageOrder,
    urlCreateInstallAnswer,
    urlImageTypes,
    urlInstallQuestions,
    urlQuestions,
    urlUpdateAssignEngineer,
} from "@/config";
import axios from "axios";


export default {
    data: () => ({
        valid: true,
        search: '',
        id: 0,
        name: '',
        nameRules: [
            v => !!v || 'name is required',
            v => (v && v.length <= 150) || 'name must be less than 150 characters',
        ],


        isActive: true,


        errors: [],
        expPanel: [0, 1, 2, 3, 4],

        buttonLoader: 'buttonLoading',
        buttonLoading: false,
        dialogAddItem: false,
        dialogReject: false,
        dialogAccept: false,
        dialogJob: false,

        title: "My Jobs",

        options: null,
        desserts: [],
        loading: true,
        headers: [
            {text: '#', value: 'id', align: 'center'},
            {text: 'Boiler type', value: 'Order.Product.BoilerType.name', align: 'center'},
            {text: 'Boiler name', value: 'Order.Product.name', align: 'center'},
            {text: 'Status', value: 'status', align: 'center'},
            {text: 'Installation Date', value: 'Order.installDate', align: 'center'},
            {text: 'Postcode', value: 'Order.AddressOrder.installPostcode', align: 'center'},
            {text: 'Actions', value: 'actions', sortable: false, align: 'center'},
        ],
        isFormTitleEdit: false,

        note: null,
        noteRules: [
            v => (!v || v.length <= 1000) || 'Note must be less than 100 characters',
        ],
        tab: 0,
        tabItems: ['Details', 'Pre-installation questionnaire', 'Post-installation questionnaire', 'Upload photo evidence'],
        item: null,
        questions: [],

        ////// Tab Form 1 index
        installQuestions: [],
        installAnswers: [],
        preQuestions: [],
        ////// Tab Form 1 index
        postQuestions: [],

        ////// Tab Form 2 index
        imageEvidences: [],
        imageSubmits: [],
        imageTypes: [],


        showDialogImage: false,
        imageUrl: null,
        image: null,
        imageRules: [
            v => !!v || 'Image is required',
            v => !v || !v.length || v[0].size < 2000000 || 'Image size should be less than 2 MB!',
        ],
    }),

    created() {
        this.getQuestionsFromApi();
        this.getInstallQuestionsFromApi();
        this.getImageTypesFromApi();
        this.getAssignEngineersFromApi();
    },


    computed: {
        formTitle() {
            return this.isFormTitleEdit ? 'Edit category' : 'Add new category'
        },
    },

    watch: {


        dialogAddItem() {
            if (!this.dialogAddItem) {
                this.isFormTitleEdit = false;
                this.clear();
            }

        },

        tab() {
            this.valid = true;
            this.buttonLoading = false;
        },

    },

    methods: {
        getAssignEngineersFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlAssignEngineers)
                .then(response => {

                    this.desserts = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });


        },

        getQuestionsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlQuestions)
                .then(response => {
                    this.questions = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getInstallQuestionsFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlInstallQuestions)
                .then(response => {
                    this.installQuestions = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        getImageTypesFromApi() {
            this.loading = true;
            networks.fetchFromWeb(urlImageTypes)
                .then(response => {
                    this.imageTypes = response.data;
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                });
        },

        statusColor(status) {
            switch (status) {
                case 1:
                    return "green";
                case 2:
                    return "red";
                case 3:
                    return "grey";
                case 4:
                    return "brown";
                case 5:
                    return "purple";
                case 6:
                    return "indigo";

                default:
                    return "amber";
            }
        },

        checkStatus(status) {
            switch (status) {
                case 1:
                    return "Accepted";
                case 2:
                    return "Declined";
                case 3:
                    return "Assign to another eng.";
                case 4:
                    return "Canceled Order";
                case 5:
                    return "Complete";
                case 6:
                    return "Recall";
                default:
                    return "Waiting";
            }
        },

        checkOrderStatus(status) {
            if (status == 0) {
                return "In process";
            } else if (status == 1) {
                return "Completed";
            } else {
                return "Canceled"
            }
        },
        statusOrderColor(status) {
            if (status == 0) {
                return "blue";
            } else if (status == 1) {
                return "green";
            } else {
                return "red";
            }
        },

        previewImage(url, isShowDialog = false) {

            if (isShowDialog) {
                this.showDialogImage = true;
                this.imageUrl = pathImageUrl + url;
            }
            return pathImageUrl + url;
        },

        downloadImage(url) {
            const urlImage = pathImageUrl + url;

            axios({
                method: 'get',
                urlImage,
                responseType: 'arraybuffer',
            })
                .then((response) => {
                    this.forceFileDownload(response, 'image.png')
                })
                .catch((e) => console.log(e))
        },

        forceFileDownload(response, title) {
            const url = window.URL.createObjectURL(new Blob([response.data]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', title)
            document.body.appendChild(link)
            link.click()
        },

        checkImageOrder(imageType) {
            return this.item.ImageOrders.filter((e) => e.ImageType.parentID == imageType.parentID && e.imageTypeID == imageType.id);
        },

        validate(isSubmit = true) {


            if (!isSubmit) {
                this.saveItem(isSubmit);
            } else if (isSubmit && this.tab == 1 && this.$refs.form_1[this.$refs.form_1.length <= this.tab ? this.$refs.form_1.length - 1 : this.tab].validate()) {
                this.saveItem(isSubmit);
            } else if (isSubmit && this.tab == 2 && this.$refs.form_2[this.$refs.form_2.length <= this.tab ? this.$refs.form_2.length - 1 : this.tab].validate()) {
                this.saveItem(isSubmit);
            } else if (isSubmit && this.tab == 3 && this.$refs.form_3[this.$refs.form_3.length <= this.tab ? this.$refs.form_3.length - 1 : this.tab].validate()) {
                if (this.imageEvidences.length > 0)
                    this.saveItem(isSubmit);
            }
        },

        saveItem(isSubmit) {

            if (this.tab == 3) {

                const fd = new FormData();

                this.imageEvidences.map((e) => fd.append("gallery", e));

                let galleryIDs = [];

                galleryIDs = this.imageEvidences.map((e, index) => index)
                galleryIDs = galleryIDs.filter(e => e != null);

                fd.append("galleryIDs", galleryIDs);
                fd.append("orderID", this.id);
                fd.append("isSaveMode", !isSubmit);

                networks.fetchFromWeb(urlCreateImageOrder, 1, fd,true)
                    .then(response => {

                        if (response.status == 200) {
                            this.imageEvidences = [];
                            alert("the data has been added successfully");
                            this.getOrderDetailsFromApi();
                        }


                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error || error.response.data;
                        this.buttonLoading = false;

                    });

            } else {

                let questions;
                questions = this.tab === 1 ? this.preQuestions : this.postQuestions;
                questions = questions.map((e, index) => {
                    return {
                        "installQuestionID": index,
                        "multiChoiceAnswer": Number(e) > -1 ? e : 0,
                        "shortAnswer": Number(e) > -1 ? null : e,
                    };
                });

                questions = questions.filter(e => e != null);

                let data = {
                    questions: questions,
                    isSaveMode: !isSubmit,
                    isPreQuestions: this.tab === 1,
                    orderID: this.id,
                };

                networks.fetchFromWeb(urlCreateInstallAnswer, 1, data)
                    .then(response => {

                        if (response.status == 200) {
                            alert("the data has been added successfully");
                            this.getOrderDetailsFromApi();
                        }
                    })
                    .catch(error => {
                        this.errors = error.response.data.errors || error.response.data.error;
                        this.buttonLoading = false;
                    });
            }

        },

        clear() {
            if (this.$refs.form)
                this.$refs.form.resetValidation();

            this.name = '';
            this.id = 0;

            this.isActive = true;

            this.valid = true;
            this.errors = [];
            this.buttonLoading = false;
        },


        editItem(item) {
            this.clear();
            this.isFormTitleEdit = true;
            this.id = item.id;
            this.name = item.name;
            this.isActive = item.isActive;
            this.dialogAddItem = true;
        },

        deleteItem(item) {
            const index = this.desserts.indexOf(item);
            confirm('do you want to delete this ?') && this.desserts.splice(index, 1)
        },

        showDialogReject(id) {
            this.id = id;
            this.dialogReject = true;
        },
        clickChangeJobStatus(isAccept = true) {
            let data = {
                engNote: !isAccept ? this.note : null,
                status: !isAccept ? 2 : 1,
            };

            networks.fetchFromWeb(urlUpdateAssignEngineer + this.id, 2, data)
                .then(response => {

                    if (response.status == 200) {
                        this.dialogReject = false;
                        this.dialogAccept = false;
                        alert("the data has been edited successfully");
                        this.getAssignEngineersFromApi();
                    }


                })
                .catch(error => {
                    this.errors = error.response.data.errors || error.response.data.error;

                }).finally(() => this.buttonLoading = false);
        },

        showDialogAccept(id) {
            this.id = id;
            this.dialogAccept = true;
        },

        showDialogJob(id) {
            this.id = id;
            this.loading = true;
            this.getOrderDetailsFromApi();

        },

        getOrderDetailsFromApi() {
            networks.fetchFromWeb(urlAssignEngineers + "/order/" + this.id)
                .then(response => {
                    this.dialogJob = true;
                    this.item = response.data;
                    this.preQuestions = [];
                    this.item.InstallAnswers.filter(e => {
                        if (e.InstallQuestion.isPreInstall)
                            this.preQuestions[e.InstallQuestion.id] = e.InstallQuestion.type > 1 ? e.shortAnswer : e.multiChoiceAnswer;
                        else this.postQuestions[e.InstallQuestion.id] = e.InstallQuestion.type > 1 ? e.shortAnswer : e.multiChoiceAnswer;

                    });
                    this.loading = false;
                })
                .catch(error => {
                    alert(error);
                    this.loading = false;
                });
        }

    },
}
</script>



